var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Company Management")]),_c('v-spacer'),_c('v-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-text-field',{staticClass:"mr-2",attrs:{"label":_vm.$i18n.translate('Filter') + ' ' + _vm.$i18n.translate('Organizations'),"flat":"","solo-inverted":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline"},on:{"click:clear":_vm.onClear},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"small":"","fab":""}},Object.assign({}, onMenu, onTooltip)),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Advanced Search")))])])]}}])},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$i18n.translate("Advanced Search")))]),_c('v-list-item',{attrs:{"selectable":""}},[_c('v-list-item-content',[_c('OrganizationTypesField',{attrs:{"dense":"","label":"Company Types"},on:{"input":_vm.onSubmit},model:{value:(_vm.filterByOrganizationTypes),callback:function ($$v) {_vm.filterByOrganizationTypes=$$v},expression:"filterByOrganizationTypes"}})],1)],1),_c('v-list-item',{attrs:{"selectable":""}},[_c('v-list-item-content',[_c('OrganizationGroupsField',{attrs:{"dense":"","label":"CPGs","category":"CPG"},on:{"input":_vm.onSubmit},model:{value:(_vm.filterByOrganizationGroups),callback:function ($$v) {_vm.filterByOrganizationGroups=$$v},expression:"filterByOrganizationGroups"}})],1)],1),_c('v-list-item',{attrs:{"selectable":""}},[_c('v-list-item-content',[_c('v-select',{attrs:{"dense":"","items":_vm.effectiveItems,"label":_vm.$i18n.translate('Active') + ' / ' + _vm.$i18n.translate('Inactive'),"clearable":""},on:{"input":_vm.onSubmit},model:{value:(_vm.filterByEffective),callback:function ($$v) {_vm.filterByEffective=$$v},expression:"filterByEffective"}})],1)],1)],1)],1)],1),(_vm.hasFilters)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"small":"","fab":""},on:{"click":_vm.onClearSearch}},Object.assign({}, onTooltip)),[_c('v-icon',[_vm._v("mdi-magnify-close")])],1)]}}],null,false,3915488936)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Clear Search")))])]):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"server-items-length":_vm.total,"options":_vm.options,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [10, 25, 50] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.organizationKey",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'cprParticipantManagement', query: { organizationId: item.id } }}},[_vm._v(" "+_vm._s(item.organizationKey)+" ")])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.address ? item.address.cityRegionPostalCountry : "")+" ")]}},{key:"item.cpg",fn:function(ref){
var item = ref.item;
return _vm._l((item.organizationGroups),function(cpg){return _c('span',{key:cpg.id},[_vm._v(" "+_vm._s(cpg.name)+" ")])})}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.effective)?_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.$i18n.translate("Active")))]):_c('v-chip',{attrs:{"color":"danger"}},[_vm._v(_vm._s(_vm.$i18n.translate("Inactive")))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }