<template>
  <v-select
    :value="selectedOrganizationGroups"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="organizationGroups"
    item-value="id"
    :item-text="item => (item.header ? item.header : `${item.name} - ${item.description}`)"
    clearable
    multiple
    return-object
  >
    <template v-slot:selection="data"> {{ data.item.name }} - {{ data.item.description }} <br /> </template>
    <template v-slot:append v-if="showNonExistingItemsWarning">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" color="warning">mdi-alert</v-icon>
        </template>
        <span v-html="nonExistingItemsWarningMessage"> </span>
      </v-tooltip>
    </template>
  </v-select>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "OrganizationGroupsField",
  props: {
    value: Array,
    category: String,
    status: { type: String, required: false }
  },
  data: () => ({
    organizationGroups: [],
    selectedOrganizationGroups: [],
    showNonExistingItemsWarning: false,
    nonExistingItemsWarningMessage: null,
    organizationGroupsLoaded: false
  }),
  methods: {
    handleNewValue(v) {
      let nonExistingElementsPresent = this.checkIfNonExistingElementsArePresent(v);
      let onlyExistingElements = this.getExistingElements(v);
      this.selectedOrganizationGroups = onlyExistingElements;
      if (nonExistingElementsPresent) {
        this.$emit("input", this.selectedOrganizationGroups);
      }
    },
    onInput() {
      this.$emit("input", this.selectedOrganizationGroups);
    },
    getExistingElements(v) {
      if (!this.organizationGroups || !v) return false;
      let organizationGroupsIds = this.organizationGroups.filter(elem => elem.id).map(elem => elem.id);
      return v.filter(elem => organizationGroupsIds.includes(elem.id));
    },
    checkIfNonExistingElementsArePresent(v) {
      if (!this.organizationGroupsLoaded || !v) return false;
      let nonExistingElements = v.filter(elem => !this.organizationGroups.map(og => og.id).includes(elem.id));
      if (nonExistingElements.length > 0) {
        this.nonExistingItemsWarningMessage = this.generateNonExistingItemsWarningMessage(nonExistingElements);
        this.showNonExistingItemsWarning = true;
        return true;
      }
      return false;
    },
    generateNonExistingItemsWarningMessage(v) {
      let message = "The following elements are selected but don't exist for the current program:<br><br><ul> ";
      message =
        message +
        v
          .map(elem => "<li>" + elem.organizationGroupKey + " - " + elem.description + " (" + elem.id + ") </li>")
          .join("");
      message = message + "</ul><br> These elements will be <b>removed</b> when saving.";
      return message;
    }
  },
  mounted() {
    let filters = {};
    if (this.category) {
      filters.category = this.category;
    }
    filters.isEffective = this.status;

    ApiService.post("/api/organizationGroups/search?size=500&sort=category,ASC&sort=name,ASC", filters).then(
      ({ data }) => {
        this.organizationGroups = data.content;
        let noCategory = { header: "No category" };
        let last = this.organizationGroups.length > 0 ? this.organizationGroups[0].category : undefined;
        if (!last) {
          this.organizationGroups.unshift(noCategory);
        } else {
          this.organizationGroups.unshift({ header: last });
        }
        for (var i = 0; i < this.organizationGroups.length; i++) {
          if (last != this.organizationGroups[i].category) {
            last = this.organizationGroups[i].category;
            let newone = { header: last };
            this.organizationGroups.splice(i, 0, newone);
          }
        }
        this.organizationGroupsLoaded = true;
        if (this.value) {
          this.handleNewValue(this.value);
        }
      }
    );
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(v) {
        this.handleNewValue(v);
      }
    }
  }
};
</script>
