<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>Company Management</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-form @submit.stop.prevent="onSubmit">
        <v-text-field
          class="mr-2"
          v-model="search"
          :label="$i18n.translate('Filter') + ' ' + $i18n.translate('Organizations')"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
        ></v-text-field>
      </v-form>
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on: onMenu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn class="mr-2" small fab v-on="{ ...onMenu, ...onTooltip }">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Advanced Search") }}</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item-group color="primary">
            <v-subheader>{{ $i18n.translate("Advanced Search") }}</v-subheader>
            <v-list-item selectable>
              <v-list-item-content>
                <OrganizationTypesField
                  dense
                  v-model="filterByOrganizationTypes"
                  label="Company Types"
                  @input="onSubmit"
                ></OrganizationTypesField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <OrganizationGroupsField
                  dense
                  v-model="filterByOrganizationGroups"
                  label="CPGs"
                  category="CPG"
                  @input="onSubmit"
                ></OrganizationGroupsField>
              </v-list-item-content>
            </v-list-item>
            <v-list-item selectable>
              <v-list-item-content>
                <v-select
                  dense
                  :items="effectiveItems"
                  v-model="filterByEffective"
                  :label="$i18n.translate('Active') + ' / ' + $i18n.translate('Inactive')"
                  clearable
                  @input="onSubmit"
                ></v-select>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-tooltip bottom v-if="hasFilters">
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn class="mr-2" small fab v-on="{ ...onTooltip }" @click="onClearSearch">
            <v-icon>mdi-magnify-close</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Clear Search") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :items="items"
        :headers="headers"
        :server-items-length="total"
        :options.sync="options"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
      >
        <template v-slot:item.organizationKey="{ item }">
          <router-link :to="{ name: 'cprParticipantManagement', query: { organizationId: item.id } }">
            {{ item.organizationKey }}
          </router-link>
        </template>
        <template v-slot:item.address="{ item }">
          {{ item.address ? item.address.cityRegionPostalCountry : "" }}
        </template>
        <template v-slot:item.cpg="{ item }">
          <span v-for="cpg in item.organizationGroups" :key="cpg.id">
            {{ cpg.name }}
          </span>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip color="primary" v-if="item.effective">{{ $i18n.translate("Active") }}</v-chip>
          <v-chip color="danger" v-else>{{ $i18n.translate("Inactive") }}</v-chip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import OrganizationGroupsField from "../../gapp-components/components/fields/OrganizationGroupsField.vue";
import OrganizationTypesField from "../../gapp-components/components/fields/OrganizationTypesField.vue";
import { mapGetters } from "vuex";

export default {
  components: { OrganizationGroupsField, OrganizationTypesField },
  name: "CprCompanyManagement",
  metaInfo: {
    title: "Company Management"
  },
  data() {
    return {
      search: "",
      filterByOrganizationTypes: [],
      filterByOrganizationGroups: [],
      loading: false,
      items: [],
      accountManagerItems: [],
      filterByEffective: "Active",
      effectiveItems: [
        { text: "Active", value: "Active" },
        { text: "Inactive", value: "Inactive" },
        { text: "All", value: "All" }
      ],
      nameFilter: "",
      headers: [
        {
          value: "accountManagers",
          text: "Account Managers",
          sortable: true
        },
        {
          value: "organizationKey",
          text: "SAP / Indirect ID",
          sortable: true
        },
        {
          value: "name",
          text: "Name",
          sortable: true
        },

        {
          value: "address",
          text: "Region",
          sortable: true
        },
        {
          value: "cpg",
          text: "CPG",
          sortable: true
        },
        {
          value: "organizationType.name",
          text: "Company Type",
          sortable: true
        },
        {
          value: "status",
          text: "Company Status",
          sortable: true,
          align: "center"
        }
      ],
      total: 0,
      options: {
        itemsPerPage: 10
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchData();
      },
      deep: true
    }
  },
  methods: {
    onClear() {
      this.search = "";
      this.fetchData();
    },
    onSubmit() {
      this.options.page = 0;
      this.fetchData();
    },
    onClearSearch() {
      this.search = "";
      this.filterByOrganizationTypes = [];
      this.filterByOrganizationGroups = [];
      this.onSubmit();
    },
    fetchData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filters = {};
      if (this.search && this.search.length > 0) {
        filters.keyword = this.search;
      }
      if (this.filterByOrganizationTypes && this.filterByOrganizationTypes.length > 0) {
        filters.organizationTypes = this.filterByOrganizationTypes.map(organizationType => {
          return {
            id: organizationType.id
          };
        });
      }
      if (this.filterByOrganizationGroups && this.filterByOrganizationGroups.length > 0) {
        filters.organizationGroups = this.filterByOrganizationGroups.map(organizationGroup => {
          return {
            id: organizationGroup.id
          };
        });
      }

      filters.isEffective = this.filterByEffective;

      this.$api
        .post(
          "/api/organizations/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.loading = false;
          this.items = data.content;
          this.total = data.totalElements;

          this.items
            .reduce((p, item) => {
              return p.then(() => this.getCpgs(item)).then(() => this.getAccountManager(item));
            }, Promise.resolve())
            .then(() => {
              this.loading = false;
              this.$forceUpdate();
            });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getCpgs(organization) {
      return this.$api.get("/api/organizations/" + organization.id + "/organizationGroups").then(({ data }) => {
        organization.organizationGroups = data._embedded.organizationGroups.reduce((orgs, org) => {
          if (org.category == "CPG") {
            orgs.push(org);
          }
          return orgs;
        }, []);
        return organization;
      });
    },
    getAccountManager(organization) {
      return this.$api
        .post("/api/relatedParticipants/search", {
          organization: { id: organization.id },
          description: "account managed by"
        })
        .then(({ data }) => {
          organization.accountManagers = data.content.map(o => o.relatedParticipant.fullName).join(", ");
        })
        .catch(e => {
          console.log("Error:" + e.error);
        });
    }
  },
  mounted() {
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      {
        text: "Admin"
      },
      { text: "Company Management" }
    ]);
  },
  computed: {
    ...mapGetters(["selectedParticipant"]),
    hasFilters() {
      let check =
        (this.search && this.search.length > 0) ||
        (this.filterByOrganizationTypes && this.filterByOrganizationTypes.length > 0) ||
        (this.filterByOrganizationGroups && this.filterByOrganizationGroups.length > 0);

      return check;
    }
  }
};
</script>
